<template>
  <div style="position: relative" class="background-color-body" v-if="projetTexte">
    <Teleport to="#titre-header"> <b style="font-size:150%" class="p-0 m-0">{{type == "perso" ? projetTexte.titres.projetPerso : projetTexte.titres.projetPro}}</b> </Teleport>
    <div class="d-flex flex-wrap justify-content-around h-100 projet">
      <div
        v-for="(projet, index) in projets"
        :key="index"
        class="m-3 card p-3 mb-0 d-flex justify-content-between"
        style="width: 320px; height: 350px"
      >
        <div>
          <div class="ml-auto mr-auto text-center">
            <img :src="require(`@/assets/logo/${projet.img}`)" width="170" />
          </div>
          <h2 class="text-center mt-3">{{ projet.nom }}</h2>
          <div class="text-justify mt-2">{{ projet.description }}</div>
        </div>
        <div
          class="d-flex justify-content-center"
          @click="
            $router.push({
              name: 'projets.informations',
              params: { type: projet.type, nomProjet: projet.code, projetId:projet.id },
            })
          "
        >
          <span class="text-info cursor-pointer"
            ><span class="fa fa-plus"></span> Plus d'infos</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./projet.type.js"></script>

<style scoped>
.projet {
  animation-name: grossir;
  animation-duration: 1s;
  animation-timing-function: ease;
  transition: all 1s;
}

@keyframes grossir {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
</style>