import communLangage from '@/commun/commun.langage.js';
import Header from '@/components/header/header.vue'
export default {
     name: 'ProjetType',

     components: {
          Header
     },

     data() {
          return {
               projetEnCours: null,
               afficherDroite: null,

               type: "perso",

      communLangage:communLangage

          }
     },

     computed: {
          langage() {
               return communLangage.langage;
          },
          projetTexte(){
               return communLangage.projetTexte;
          },
          projets(){
               return this.projetTexte?.projets.filter(x => x.type == this.type);
          }
     },

     methods: {
          
     },

     created() {
          this.type = this.$route.params.type;

     }
}